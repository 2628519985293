'use client';

import { CalculatorIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import { differenceInDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, Loader } from 'ui';
import { displayPrice, pluralize, thousandsSeparator } from 'utils';
import { RailsItemSwapInsights, fetchItemOrSwapInsights } from '~/api/item';
import FeatheredOverflowContainer from '~/components/FeatheredOverflowContainer';
import Image from '~/components/Image';
import NewModalCloseActionButton from '~/components/NewModal/NewModalCloseActionButton';
import SimpleModal from '~/components/NewModal/SimpleModal';
import LegacyInsights from '~/components/SidelinePro/Insights/LegacyInsights';
import SidelineProPaywallModal from '~/components/SidelinePro/Paywall/PaywallModal';
import ItemOffersTable from '~/components/SwapDetails/Information/ItemOffersTable';
import {
  StyledTableCell,
  StyledTableRow,
} from '~/components/Tables/TableElements';
import useHasFeature from '~/hooks/useHasFeature';
import { useIsSidelineProAvailable } from '~/hooks/useIsSidelinePro';
import EyeIcon from '~/public/static/svg/EyeIcon';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { RailsItem } from '~/typings/services/rails/item';
import { RailsSwapDetail } from '~/typings/services/rails/swaps';

export default function ProItemSwapInsights({
  asChild,
  children,
  item,
  swapDetails,
}: {
  asChild?: boolean;
  children?: React.ReactNode;
  swapDetails?: RailsSwapDetail;
  item: RailsItem | RailsSwapDetail['item'];
}) {
  const proAvailable = useIsSidelineProAvailable();
  if (!proAvailable) {
    return (
      <LegacyInsights item={item} swapDetails={swapDetails} asChild={asChild}>
        {children}
      </LegacyInsights>
    );
  }

  return (
    <ProInsights item={item} swapDetails={swapDetails} asChild={asChild}>
      {children}
    </ProInsights>
  );
}

function ProInsights({
  asChild,
  children,
  item,
  swapDetails,
}: {
  asChild?: boolean;
  children?: React.ReactNode;
  swapDetails?: RailsSwapDetail;
  item: RailsItem | RailsSwapDetail['item'];
}) {
  const [open, setOpen] = useState(false);
  const [paywallOpen, setPaywallOpen] = useState(false);
  const proAvailable = useIsSidelineProAvailable();
  const hasInsightsFeature = useHasFeature('insights');
  const mobile = useMobile();
  const [query, setQuery] = useState<Record<string, string>>({});

  const { data: insights } = useQuery(
    ['insights', item.id, query],
    () =>
      fetchItemOrSwapInsights(item.id, swapDetails?.id, Object.values(query)),
    {
      enabled: proAvailable && hasInsightsFeature && open,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    if (open && insights && Object.keys(query).length === 0) {
      setQuery(
        insights.item.details.reduce((acc, cur) => {
          if (cur.value_guides_required) {
            acc[cur.name] = cur.values?.[0]?.slug;
          }
          return acc;
        }, {}),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insights]);

  if (!proAvailable) {
    return null;
  }

  function handleOpen() {
    if (!hasInsightsFeature) {
      setPaywallOpen(true);
    } else {
      setOpen(true);
    }
  }

  return (
    <>
      {asChild ? (
        children ? (
          React.Children.map(
            children as React.ReactElement<{
              onClick?: React.MouseEventHandler;
            }>,
            child =>
              React.cloneElement(child, {
                onClick: handleOpen,
              }),
          )
        ) : null
      ) : (
        <button
          onClick={handleOpen}
          className="text-sm font-normal underline"
          type="button"
        >
          {children}
        </button>
      )}

      {insights ? (
        <SimpleModal
          title={mobile ? 'Insights' : insights.item.name}
          open={open}
          onClose={() => setOpen(false)}
          size="xl"
        >
          {mobile ? (
            <MobileInsights
              insights={insights}
              query={query}
              setQuery={setQuery}
            />
          ) : (
            <DesktopInsights
              insights={insights}
              query={query}
              setQuery={setQuery}
            />
          )}

          <NewModalCloseActionButton fluid>Close</NewModalCloseActionButton>
        </SimpleModal>
      ) : (
        <SimpleModal
          title="Insights"
          open={open}
          onClose={() => setOpen(false)}
          size="xl"
          bodyClassName="space-y-6"
        >
          <Loader loading />
        </SimpleModal>
      )}

      {!hasInsightsFeature && (
        <SidelineProPaywallModal
          open={paywallOpen}
          onClose={() => setPaywallOpen(false)}
          source="Insights"
        />
      )}
    </>
  );
}

function PriceColumns({ insights }: { insights: RailsItemSwapInsights }) {
  return (
    <>
      <div>
        <div className="font-semibold">Ask Price:</div>
        <div className="text-xl">{displayPrice(insights.item.price)}</div>
        <div className="text-slate-green-500 text-sm">
          {insights.item.shipping_discount_amount != null &&
            `+ ${displayPrice(
              insights.item.shipping_discount_amount,
            )} Shipping`}
        </div>
      </div>

      <div>
        <div className="font-semibold">Offer:</div>
        <div className="text-xl">
          {insights.swap?.offer
            ? displayPrice(insights.swap.offer.amount)
            : 'N/A'}
        </div>
        <div className="text-slate-green-500 text-sm" />
      </div>

      <div>
        <div className="font-semibold">Your Cost:</div>
        <div className="text-xl">
          {insights.item.cost ? displayPrice(insights.item.cost) : 'N/A'}
        </div>
        <div className="text-slate-green-500 text-sm"></div>
      </div>
    </>
  );
}

function ViewsFavorites({ insights }: { insights: RailsItemSwapInsights }) {
  return (
    <div className="grid grid-cols-2 gap-2 text-sm font-semibold">
      <div className="flex items-center gap-2">
        <FavoriteIcon fill="currentColor" className="text-green-500" />
        <div>{insights.item.favoriters_count}</div>
      </div>

      <div className="flex items-center gap-2">
        <EyeIcon fill="currentColor" className="text-green-500" />
        <div>{insights.item.views_count}</div>
      </div>
    </div>
  );
}

function SuggestedPriceAndFilters({
  insights,
  query,
  setQuery,
}: {
  insights: RailsItemSwapInsights;
  query: Record<string, string>;
  setQuery: (query: Record<string, string>) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="mb-3 flex items-center">
        <div className="flex-1 text-lg font-semibold">
          Suggested Price: {displayPrice(insights.value_guide.suggested_price)}
        </div>

        <div>
          <Button
            className="flex items-center gap-2 !px-2"
            variant={open ? 'contained' : 'outlined'}
            onClick={() => setOpen(!open)}
          >
            <CalculatorIcon className="h-5 w-5 text-green-500" />
            <span>Filters</span>
          </Button>
        </div>
      </div>

      {open && (
        <div className="rounded border px-4 py-2.5">
          <div className="mb-4">
            Select a detail to include it in the value guide pricing. Select
            again to exclude it.
          </div>

          <div className="flex flex-wrap gap-2">
            {insights.item.details.map(detail => {
              const selected = !!query[detail.name];

              return (
                <button
                  className={clsx('rounded-full px-3 py-1 font-semibold', {
                    'bg-turf-green-500 border border-transparent text-white':
                      selected,
                    'border bg-white': !selected,
                  })}
                  onClick={() => {
                    if (selected) {
                      const newQuery = {
                        ...query,
                      };
                      delete newQuery[detail.name];
                      setQuery(newQuery);
                    } else {
                      setQuery({
                        ...query,
                        [detail.name]: detail.values?.[0]?.slug,
                      });
                    }
                  }}
                >
                  {detail.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

function LowMediumHighPill({
  label,
  color,
}: {
  color: 'green' | 'yellow' | 'red';
  label: string;
}) {
  return (
    <div
      className={clsx(
        'inline-block rounded px-1.5 py-0.5 text-xs font-semibold uppercase',
        {
          'bg-green-100 text-green-500': color === 'green',
          'bg-yellow-100 text-yellow-500': color === 'yellow',
          'bg-red-100 text-red-500': color === 'red',
        },
      )}
    >
      {label}
    </div>
  );
}

function SellThroughRate({ insights }: { insights: RailsItemSwapInsights }) {
  const str = insights.value_guide?.sell_through_rate?.percentage * 100 || 0;

  let strLowMediumHigh: 'LOW' | 'MEDIUM' | 'HIGH' | undefined;
  let strPillColor: 'green' | 'yellow' | 'red' | undefined;
  if (insights.value_guide.sell_through_rate.rating === 'high') {
    strLowMediumHigh = 'HIGH';
    strPillColor = 'green';
  } else if (insights.value_guide.sell_through_rate.rating === 'medium') {
    strLowMediumHigh = 'MEDIUM';
    strPillColor = 'yellow';
  } else if (insights.value_guide.sell_through_rate.rating === 'low') {
    strLowMediumHigh = 'LOW';
    strPillColor = 'red';
  }

  const bar = (
    <div>
      <div className="relative mb-1 flex">
        <div className="text-slate-green-500 flex-1 text-xs">0%</div>

        <div
          className="absolute"
          style={{
            top: '-90%',
            left: `min(max(-12px, calc(${str}% - 2rem)), calc(100% - 2rem))`,
          }}
        >
          <div className="relative text-center">
            <div className="bg-turf-green-500 bottom-full right-0 rounded px-2 py-1 font-semibold text-white">
              {str}%
              <svg
                className="text-turf-green-500 absolute left-0 top-full z-10 h-3 w-full"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xmlSpace="preserve"
              >
                <polygon
                  className="fill-current"
                  points="0,0 127.5,127.5 255,0"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="text-slate-green-500 flex-1 text-right text-xs">
          100%
        </div>
      </div>

      <div className="relative h-3 rounded bg-gradient-to-r from-red-300 via-yellow-300 to-green-300 shadow-inner" />
    </div>
  );

  return (
    <div className="flex flex-col rounded border px-4 py-2.5">
      <div className="mb-6 flex flex-1">
        <div className="flex-1">
          <div className="font-semibold">Sell-Through Rate</div>
          {insights.value_guide.recent_listings > 0 && (
            <div className="text-slate-green-500 text-sm">
              Based on {insights.value_guide.recent_listings}{' '}
              {pluralize(
                insights.value_guide.recent_listings,
                'listing',
                'listings',
              )}
            </div>
          )}
        </div>
        <div>
          {str > 0 && strPillColor && strLowMediumHigh && (
            <LowMediumHighPill label={strLowMediumHigh} color={strPillColor} />
          )}
        </div>
      </div>

      {bar}
    </div>
  );
}

function AvgResalePrice({ insights }: { insights: RailsItemSwapInsights }) {
  const bar = (
    <div>
      <div className="mb-1 flex items-baseline">
        <div className="flex-1 text-sm">
          {displayPrice(insights.value_guide.avg_resale_price.low)}
        </div>

        <div className="">
          <div className="relative mx-2 text-center">
            <div className="bg-turf-green-500 bottom-full right-0 rounded px-2 py-1 font-semibold text-white">
              {displayPrice(insights.value_guide.avg_resale_price.median)}
              <svg
                className="text-turf-green-500 absolute left-0 top-full z-10 h-3 w-full"
                x="0px"
                y="0px"
                viewBox="0 0 255 255"
                xmlSpace="preserve"
              >
                <polygon
                  className="fill-current"
                  points="0,0 127.5,127.5 255,0"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex-1 text-right text-sm">
          {displayPrice(insights.value_guide.avg_resale_price.high)}
        </div>
      </div>

      <div className="relative h-3 rounded bg-gradient-to-r from-red-300 via-yellow-300 to-green-300 shadow-inner" />
    </div>
  );

  return (
    <div className="flex flex-col rounded border px-4 py-2.5">
      <div className="mb-6 flex flex-1">
        <div className="flex-1">
          <div className="font-semibold">Avg. Resale Price</div>
          {insights.value_guide.recent_sales > 0 && (
            <div className="text-slate-green-500 text-sm">
              Based on {insights.value_guide.recent_sales}{' '}
              {pluralize(insights.value_guide.recent_sales, 'sale', 'sales')}
            </div>
          )}
        </div>

        <div />
      </div>

      {bar}
    </div>
  );
}

function RecentSales({ insights }: { insights: RailsItemSwapInsights }) {
  return (
    <div className="rounded border px-4 py-2.5">
      <div className="mb-2 text-lg font-semibold">
        Recent Sales ({insights.value_guide.recent_sales})
      </div>

      {insights.recent_sales.length > 0 ? (
        <FeatheredOverflowContainer scrollButton className="flex gap-2">
          {insights.recent_sales.map((item, index) => (
            <div
              key={index}
              className="group relative flex-shrink-0 rounded border"
            >
              <Image
                src={item.images[0]}
                alt={item.name}
                className="h-28 w-32 rounded-t object-cover"
              />

              <div className="p-2">
                <div className="mb-2 text-sm font-semibold">
                  {displayPrice(item.sold_price)}
                </div>

                <div>
                  <LowMediumHighPill
                    color={
                      item.time_to_sell < 30
                        ? 'green'
                        : item.time_to_sell < 60
                          ? 'yellow'
                          : 'red'
                    }
                    label={`${thousandsSeparator(
                      Math.round(item.time_to_sell),
                    )} days`}
                  />
                </div>
              </div>

              <div className="bg-turf-green-500 invisible absolute left-0 top-0 flex h-full w-full flex-col rounded p-1.5 text-xs text-white opacity-0 transition-opacity duration-200 group-hover:visible group-hover:opacity-100">
                <div className="line-clamp-4 flex-1">{item.name}</div>

                <hr className="border-turf-green-400 my-1.5" />

                <div className="text-2xs flex-1">
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td>Sold Price</td>
                        <td className="text-right">
                          {displayPrice(item.sold_price)}
                        </td>
                      </tr>
                      <tr>
                        <td>List Price</td>
                        <td className="text-right">
                          {displayPrice(item.list_price)}
                        </td>
                      </tr>
                      <tr>
                        <td>Days to Sell</td>
                        <td className="text-right">
                          {thousandsSeparator(Math.round(item.time_to_sell))}
                        </td>
                      </tr>
                      <tr>
                        <td>List Date</td>
                        <td className="text-right">
                          {new Date(item.published_at).toLocaleDateString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </FeatheredOverflowContainer>
      ) : (
        <div className="text-slate-green-500 font-semibold italic">
          No recent sales
        </div>
      )}
    </div>
  );
}

function RecentOffers({ insights }: { insights: RailsItemSwapInsights }) {
  return (
    <div>
      <div className="mb-4 text-lg font-semibold">Recent Offers</div>

      <div className="rounded border px-4 py-2.5">
        <ItemOffersTable item={insights.item} />
      </div>
    </div>
  );
}

function Details({ insights }: { insights: RailsItemSwapInsights }) {
  return (
    <>
      <div className="mb-4 text-lg font-semibold">Details</div>

      <div className="rounded border px-4 py-2.5">
        <table className="w-full">
          <tbody>
            <StyledTableRow>
              <StyledTableCell className="font-semibold">
                Event Name
              </StyledTableCell>
              <StyledTableCell>
                {insights.item.event_name || <i>N/A</i>}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className="font-semibold">
                Item ID
              </StyledTableCell>
              <StyledTableCell>{insights.item.id}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className="font-semibold">
                Warehouse Location
              </StyledTableCell>
              <StyledTableCell>
                {insights.item.location_id || <i>N/A</i>}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell className="font-semibold">Cost</StyledTableCell>
              <StyledTableCell>
                {insights.item.cost ? (
                  displayPrice(insights.item.cost)
                ) : (
                  <i>N/A</i>
                )}
              </StyledTableCell>
            </StyledTableRow>
          </tbody>
        </table>
      </div>
    </>
  );
}

function MobileInsights({
  insights,
  query,
  setQuery,
}: {
  insights: RailsItemSwapInsights;
  query: Record<string, string>;
  setQuery: (query: Record<string, string>) => void;
}) {
  return (
    <div>
      <div className="mb-4 flex gap-2">
        <Image
          src={insights.item.primary_image?.edge_url}
          alt={insights.item.name}
          className="row-span-2 h-32 w-32 rounded object-cover"
          useFastly={{
            width: 300,
          }}
        />

        <div className="flex-1">
          <div className="mb-4 text-lg font-semibold">{insights.item.name}</div>

          <div
            className="text-sm"
            title={new Date(insights.item.published_at!)?.toLocaleDateString()}
          >
            Published{' '}
            {thousandsSeparator(
              differenceInDays(
                new Date(),
                new Date(insights.item.published_at!),
              ),
            )}{' '}
            days ago
          </div>

          <ViewsFavorites insights={insights} />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <PriceColumns insights={insights} />
      </div>

      <hr className="-mx-6 mb-4 mt-2" />

      <div className="mb-4 space-y-4">
        <SuggestedPriceAndFilters
          insights={insights}
          query={query}
          setQuery={setQuery}
        />

        <div className="space-y-4">
          <AvgResalePrice insights={insights} />
          <SellThroughRate insights={insights} />
        </div>

        <RecentSales insights={insights} />

        <RecentOffers insights={insights} />

        <Details insights={insights} />
      </div>
    </div>
  );
}

function DesktopInsights({
  insights,
  query,
  setQuery,
}: {
  insights: RailsItemSwapInsights;
  query: Record<string, string>;
  setQuery: (query: Record<string, string>) => void;
}) {
  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <Image
          src={insights.item.primary_image?.edge_url}
          alt={insights.item.name}
          className="row-span-2 rounded"
          useFastly={{
            width: 300,
          }}
        />

        <PriceColumns insights={insights} />

        <div
          className="text-sm"
          title={new Date(insights.item.published_at!)?.toLocaleDateString()}
        >
          Published{' '}
          {thousandsSeparator(
            differenceInDays(new Date(), new Date(insights.item.published_at!)),
          )}{' '}
          days ago
        </div>

        <ViewsFavorites insights={insights} />
      </div>

      <hr className="-mx-6 mb-8 mt-4" />

      <div className="mb-4 space-y-4">
        <SuggestedPriceAndFilters
          insights={insights}
          query={query}
          setQuery={setQuery}
        />

        <div className="grid grid-cols-2 gap-4">
          <AvgResalePrice insights={insights} />
          <SellThroughRate insights={insights} />
        </div>

        <RecentSales insights={insights} />

        <RecentOffers insights={insights} />

        <Details insights={insights} />
      </div>
    </div>
  );
}
